<template>
  <div>
    <div v-if="left" class="benefits">
      <div class="benefits__left" :id="id">
        <div class="benefits__title">
          <i class="features__icon" :class="icon"></i> {{ " " }}
          <h1 class="benefits__h1" itemProp="name">{{ title }}</h1>
        </div>

        <p class="benefits__text" itemProp="description">
          {{ text }}
        </p>
      </div>
      <div
        class="benefits__right holder fadeInLeft"
        v-wow="{ 'animation-name': 'fadeInLeft', 'animation-duration': '1.3s' }"
      >
       
          <img
            class="animate__animated animate__bounce benefits__img img-fluid"
            itemProp="image"
            :src="src_img"
            :alt="alt_img"
          />
      
      </div>
    </div>
    <div v-if="right" class="benefits-s" :id="id">
      <div v-if="comming_soon" class="content_comming_soon">
        <img class="comming_soon" src="../assets/img/proximamente.png" alt="Imagen de Proximamente modulo TireCheck">
      </div>

      <div class="clip-back"></div>
      <div
        class="benefits__left holder fadeInLeft"
        v-wow="{ 'animation-name': 'fadeInLeft', 'animation-duration': '1.3s' }"
      >
        <img
          class="benefits__img img-fluid"
          itemProp="image"
          :src="src_img"
          :alt="alt_img"
        />
      </div>
      <div class="benefits__right">
        <div class="benefits__title">
          <img v-if="icon_img"  class="features__tire" src="../assets/img/fa-tire-s.svg" alt="Icono de TireCheck.">
          <i v-if="!icon_img" class="features__icon features__icon--white" :class="icon"></i> {{ " " }}
          <h2 class="benefits-s__h1" itemProp="name">
            {{title}}
          </h2>
        </div>
        <p class="benefits-s__text" itemProp="description">
          {{text}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Beneficio",
  data:{
    comming_soon: false,
  },
  props: ["left", "right", "comming_soon", "id", "icon", "icon_img", "title", "text", "src_img", "alt_img"],
};
</script>

<style>
.content_comming_soon{
  position: absolute;
}

.comming_soon{
  width: 250px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 2000;
}

@media screen and (max-width: 500px){
  .comming_soon{
    width: 150px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.benefits__right-content-img{
  background: var(--white-secondary);
  border: 1px solid var(--black);
  border-radius: 10px;
  padding: 5px;
}

.animate__animated{
  border-radius: 10px;
}

#dos {
  background-image: url("../assets/img/gestionderiesgosop_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#cuatro {
  background-image: url("../assets/img/gestiondemantenimiento_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#seis {
  background-image: url("../assets/img/capacitacion_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

#ocho {
  background-image: url("../assets/img/tirecheck_back.jpg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.clip-back {
  position: absolute;
  width: 100%;
  height: inherit;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(--primary) 0%, #298448 100%) 0% 0% no-repeat
    padding-box;
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .clip-back {
    opacity: 0.6;
  }
}

.benefits {
  background-image: url("../assets/img/back.svg");
  background-repeat: no-repeat;
  background-image: fixed;
  /* background-image: center; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  padding: 100px 0;
  /* background: var(--white); */
  border-top: 0.5px solid #e4e4e4;
}
@media screen and (max-width: 991px) {
  .benefits {
    grid-template-columns: 100%;
    padding: 50px 0 40px 0;
  }
}

.benefits__left {
  display: grid;
  z-index: 1000;
  padding-left: 15%;
}
@media screen and (max-width: 991px) {
  .benefits__left {
    justify-items: center;
    margin-bottom: 20px;
    padding: 30px 7% 0 7%;
  }
}

.benefits__title {
  display: flex;
  align-items: center;
  justify-self: start;
}

.features__check {
  height: 30px;
  margin-right: 10px;
}

.benefits__h1 {
  display: inline-block;
  font-size: 40px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
  color: #131313;
}
@media screen and (max-width: 1199px) {
  .benefits__h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 991px) {
  .benefits__h1 {
    font-size: 45px;
  }
}
@media screen and (max-width: 767px) {
  .benefits__h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 550px) {
  .benefits__h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .benefits__h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 240px) {
  .benefits__h1 {
    font-size: 20px;
  }
}

.benefits__text {
  font-size: 20px;
  margin-bottom: 0;
  color: #131313;
}
@media screen and (max-width: 480px) {
  .benefits__text {
    font-size: 17px;
  }
}

@media screen and (max-width: 240px) {
  .benefits__text {
    font-size: 14px;
  }
}

.benefits__right {
  z-index: 500;
  justify-self: center;
  padding-right: 15%;
  padding-bottom: 30px;
}
@media screen and (max-width: 991px) {
  .benefits__right {
    padding: 0 7%;
  }
}

.benefits__img {
  max-width: 550px;
}
@media screen and (max-width: 1199px) {
  .benefits__img {
    max-width: 350px;
  }
}
@media screen and (max-width: 991px) {
  .benefits__img {
    max-width: 100%;
  }
}


.benefits-s {
  display: grid;
  height: 450px;
  grid-template-columns: 50% 50%;
  align-items: center;
  background: var(--primary);
}

@media screen and (max-width: 991px) {
  .benefits-s {
    grid-template-columns: 100%;
    height: 860px;
  }
}

@media screen and (max-width: 480px) {
  .benefits-s {
    height: 770px;
  }
}

.benefits-s__h1 {
  display: inline-block;
  font-size: 40px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

@media screen and (max-width: 1199px) {
  .benefits-s__h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 991px) {
  .benefits-s__h1 {
    font-size: 45px;
  }
}

@media screen and (max-width: 767px) {
  .benefits-s__h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 480px) {
  .benefits-s__h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .benefits-s__h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 240px) {
  .benefits-s__h1 {
    font-size: 20px;
  }
}

.benefits-s__text {
  font-size: 20px;
  margin-bottom: 0;
  color: white;
}

@media screen and (max-width: 1199px) {
  .benefits-s__text {
    font-size: 17px;
  }
}

@media screen and (max-width: 240px) {
  .benefits-s__text {
    font-size: 14px;
  }
}

.features__icon{
    font-size: 30px;
    font-weight: 300;
    color: var(--primary);
    width: 40px;
}

.features__icon--white{
  color: var(--white);
}

.features__tire{
    width: 30px;
    margin-right: 15px;
    padding-bottom: 5px;
}
</style>