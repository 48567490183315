<template>
  <div>
    <Menu />

    <Beneficio 
      id="uno"
      left="true" 
      icon="fal fa-tasks" 
      title="CheckList Electrónico" 
      text="Construido especialmente para comprobar de forma ordenada y
          sistemática, el cumplimiento de los requisitos operacionales y de
          seguridad de sus equipos, permitiendo una gestión eficaz de los
          riesgos de su operación. Consiste en un formato digital mediante el
          cual los operadores, pueden reportar de manera práctica e inmediata
          las condiciones de su equipo de trabajo, antes, durante y después de
          su operación."
      :src_img="Checklist"
      alt_img="Imagen de interfaz para Checklist eléctronico."
    />

    <Beneficio 
      id="dos"
      right="true" 
      icon="fal fa-exclamation-circle" 
      title="Gestión de Riesgos Operativos" 
      text="Generación de información estructurada que permite la gestión de los
          peligros que amenazan su operación, los cuales requieren de su plena
          identificación y caracterización para el establecimiento de
          estrategias de control y mitigación, previniendo la ocurrencia de
          accidentes que comprometan la integridad de las personas, impacten el
          medio ambiente y evitando perdidas económicas que afecten a su
          organización." 
      :src_img="Riesgos"
      alt_img="Imagen de interfaz para Gestión de Riesgos Operativos."
    />

    <Beneficio 
      id="tres"
      left="true" 
      icon="fal fa-folder" 
      title="Gestión Documental" 
      text="Generamos información estructurada relativa a la documentación
          requerida para el desarrollo de las operaciones de su empresa,
          alertando la vigencia de documentos fundamentales de equipos y
          personas; permitiendo la oportuna actualización y evitando
          incumplimientos que conlleven a multas y/o sanciones por parte de sus
          clientes y autoridades." 
      :src_img="Documental"
      alt_img="Imagen de interfaz para Gestión documental."
    />

    <Beneficio 
      id="cuatro"
      right="true" 
      icon="fal fa-wrench" 
      title="Gestión de Mantenimiento" 
      text="Este módulo permite optimizar de forma integral los procesos de 
      mantenimiento de equipos, suministrando información veraz, oportuna y 
      trazable de las condiciones de operatividad; convirtiéndose en la herramienta 
      fundamental, para el ajuste de sus cronogramas de mantenimiento predictivo y 
      preventivo. A la vez que aumenta el nivel de asertividad del mantenimiento 
      correctivo, evitando así perdidas económicas que comprometan la imagen de su empresa." 
      :src_img="Mantenimiento"
      alt_img="Imagen de interfaz para Gestión de Mantenimiento."
    />

    <Beneficio 
      id="cinco"
      left="true" 
      icon="fal fa-shield-check" 
      title="Gestión Riesgos Laborales" 
      text="Qinspecting contribuye a la identificación y el control de condiciones
          de trabajo desfavorables, que puedan generar la ocurrencia de
          accidentes que comprometen la integridad de las personas, el medio
          ambiente y la imagen de su compañía." 
      :src_img="Laborales"
      alt_img="Imagen de interfaz para Gestión Riesgos Laborales."
    />

    <Beneficio 
      id="seis"
      right="true" 
      icon="fal fa-users" 
      title="Gestión de Programas de Capacitación" 
      text="Herramienta digital intuitiva, que permite evaluar a su personal,
          haciendo entrega eficaz de materiales de capacitación. Desarrollado
          para mejorar de manera práctica la gestión de capacitaciones,
          aumentando el nivel de aprendizaje, cobertura, desempeño y seguridad
          en cada uno de sus procesos." 
      :src_img="Capacitacion"
      alt_img="Imagen de interfaz para Gestión de Programas de Capacitación."
    />

    <Beneficio 
      id="siete"
      left="true" 
      icon="fal fa-graduation-cap" 
      title="Cursos Certificados" 
      text="Este módulo optimiza el desempeño de los programas de capacitación 
      y formación, dirigidos a validar y aumentar las competencias e idoneidad 
      indispensables para desempeñar los cargos estratégicos de su organización; 
      permitiendo programar y acceder de manera práctica a capacitaciones 
      certificadas por Instituciones para el Trabajo y el Desarrollo Humano, 
      avaladas por las autoridades de educación correspondientes." 
      :src_img="Certificados"
      alt_img="Imagen de interfaz para Cursos Certificados."
    />

    <Beneficio 
      id="ocho"
      right="true" 
      icon="fal fa-graduation-cap" 
      :icon_img="true"
      :comming_soon="true"
      title="TireCheck" 
      text="Módulo de apoyo al proceso de mantenimiento, diseñado especialmente
          para las empresas de transporte terrestre, que permite una eficiente 
          administración de las llantas, catalogádas como un insumo de alto valor 
          económico y estratégico, que requiere de permanente vigilancia y control. 
          Esta herramienta suministra información estructurada y detallada del uso 
          y la durabilidad de cada una de sus llantas instaladas o en inventario; 
          aumentando niveles de reencauchabilidad, vida util de las llantas e 
          incrementando la rentabilidad de su empresa." 
      :src_img="TireCheck"
      alt_img="Imagen de interfaz para TireCheck."
    />

     <Beneficio 
      id="nueve"
      left="true" 
      icon="fal fa-chart-pie" 
      title="Informes de Desempeño" 
      text="Qinspecting, genera informes gerenciales y detallados de desempeño,
            que contribuyen a la toma de decisiones, simplificando
            exponencialmente los procesos de su compañía. Permitiendo a su vez,
            una mejor lectura del desempeño de la organización, sus procesos y
            su personal. Siendo fundamentales para el establecimiento de
            estrategias de mejora continua." 
      :src_img="Informes_Desempeño"
      alt_img="Imagen de interfaz para Informes de Desempeño."
    />

    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import Beneficio from '@/components/Beneficio'

// imports images
import Checklist from '@/assets/img/checklist.png'
import Riesgos from '@/assets/img/riesgos.png'
import Documental from '@/assets/img/documental.png'
import Mantenimiento from '@/assets/img/mantenimiento.png'
import Laborales from '@/assets/img/laborales.png'
import Capacitacion from '@/assets/img/capacitacion.png'
import Certificados from '@/assets/img/certificados.png'
import TireCheck from '@/assets/img/tirecheck.png'
import Informes_Desempeño from '@/assets/img/informes_desempeño.png'


export default {
  name: "Beneficios",
  data(){
    return{
      Checklist: Checklist,
      Riesgos: Riesgos,
      Documental: Documental,
      Mantenimiento: Mantenimiento,
      Laborales: Laborales,
      Capacitacion: Capacitacion,
      Certificados: Certificados,
      TireCheck:TireCheck,
      Informes_Desempeño: Informes_Desempeño
    }
  },
  //   showItem: true
  // },
  components: {
    Menu,
    Beneficio,
    Footer,
  },
  metaInfo() {
    return {
      title: "Qinspecting |",
      titleTemplate: "%s Múltiples beneficios para su empresa a un buen precio.",
      meta: [
        {
          name: "description",
          content:
            "Qinspecting, gestión de flotas con checklist digitales, gestión de personal, gestión documental, mantenimiento, informes de desempeño, cursos certificados, TireCheck.",
        },
        {
          name: "keywords",
          content:
            "Qinspecting, checklist, preoperacionales, alistamientos, Transportes, Inspecciones, ChecklistElectronicos, Truck, mantenimiento, operaciones, hseq , talentohumano, gerencia, protocolodebioseguridad, protocolos, bioseguridad, Appears, Qinspecting",
        },
        {
          name: "author",
          content: "Appears",
        },
        {
          name: "robots",
          content: "Index, Follow",
        },
        // Tags twitter
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:site",
          content: "@appears",
        },
        {
          name: "twitter:creator",
          content: "@appears",
        },
        {
          name: "twitter:title",
          content:
            "Qinspecting | Múltiples beneficios para su empresa a un buen precio.",
        },
        {
          name: "twitter:description",
          content:
            "Qinspecting, gestión de flotas con checklist digitales, gestión de personal, gestión documental, mantenimiento, informes de desempeño, cursos certificados, TireCheck.",
        },
        {
          name: "twitter:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        // Tags facebook
        {
          property: "og:locale",
          content: "es_CO",
        },
        {
          property: "og:site_name",
          content: "Qinspecting",
        },
        {
          property: "og:url",
          content: "https://www.qinspecting.com",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content:
            "Qinspecting | Múltiples beneficios para su empresa a un buen precio.",
        },
        {
          property: "og:description",
          content:
            "Qinspecting, gestión de flotas con checklist digitales, gestión de personal, gestión documental, mantenimiento, informes de desempeño, cursos certificados, TireCheck.",
        },
        {
          property: "og:image",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:secure_url",
          content: "https://qinspecting.com/img/qinspecting.svg",
        },
        {
          property: "og:image:width",
          content: "600",
        },
        {
          property: "og:image:height",
          content: "323",
        },
      ],      
    }
  },
};
</script>

